@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

:root {

  --primary: rgb(17 24 39);
  --secondary:  rgb(31 41 55);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--secondary);
 
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);

  border: 3px solid var(--primary);
}

/* ---------- Global Layout-Container ---------- */
.page-container {
 @apply flex flex-col bg-primary-main-background items-center justify-center px-6 py-8 overflow-hidden; 
}


/* --------------- Navigation Styles  ---------------*/
.nav-container { 
 @apply bg-primary-nav border-gray-200 dark:bg-gray-900;

}

.nav-wrapper {
  @apply max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4;
}

.nav-menu {
  @apply font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 
  rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 
  md:bg-primary-nav dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700;
}

.nav-menu button {
  color:white; 
  transition: color 0.3s ease-in-out; 
}

.nav-menu button:hover{
  color: #22c55e; 
}

.nav-link {
  @apply block py-2 pl-3 pr-4 text-gray-900 rounded 
  hover:bg-gray-100 md:hover:bg-transparent md:border-0 
  md:hover:text-white md:p-0 dark:text-white md:dark:hover:text-blue-500 
  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent;
}

.nav-link-activate{
  color: #84d994 !important;
  font-weight: 700;
  

}

/* ------------------------------ Table Styles --------------------------------------------- */
.table-container {
  @apply items-center w-full px-4 py-4 mx-auto my-10 bg-primary-nav
         rounded-lg shadow-md sm:w-11/12 max-h-screen overflow-auto no-scrollbar;
}

.table-wrapper {
  @apply container mx-auto;
}

.table-header {
  @apply flex justify-between w-full px-4 py-2 items-center;
}

.table-heading {
  @apply text-xl font-bold uppercase text-gray-100;
}

.table-button-container {
  @apply px-4 py-2;
}

.table-scroll-container {
  @apply mt-6 overflow-x-auto w-full;
}

.table-layout {
  @apply flex flex-col w-full table-fixed;
}

.table-head {
  @apply flex;
}

.table-row-header {
  @apply flex w-full text-sm text-gray-100 font-semibold text-center uppercase;
}

.table-row-header th {
  @apply px-4 py-3 w-1/2;
}

.table-body {
  @apply flex flex-col w-full text-sm font-normal text-gray-100 text-center 
         overflow-y-auto max-h-128;
}

.table-row{
  @apply flex bg-primary-nav hover:bg-gray-500 font-medium transition-all duration-300 cursor-pointer;
}

.table-cell {
  @apply px-6 py-4 w-1/4 text-center;
}

/* ------------------------- Span Styling ------------------------- */
.span-container {
  @apply ml-2 text-sm text-white sm:text-base dark:text-gray-200;
}





/* ------------------------- View-Details Styling ------------------------- */
.view-container {
  @apply flex flex-col items-center w-full;
}

.view-card {
  @apply flex items-center w-full sm:max-w-md px-4 py-4 my-10 
         bg-primary-nav rounded-lg shadow-md sm:w-11/12 overflow-auto;
}

.view-card-container {
  @apply container;
}

.view-card-header {
  @apply flex justify-center w-full px-4 py-2 items-center;
}

.view-card-title {
  @apply text-xl justify-center font-bold uppercase text-gray-100;
}

.view-details-row {
  @apply flex flex-row items-center justify-center mt-6;
}

.view-details-label {
  @apply flex flex-row text-gray-100 basis-1/2 font-bold justify-center;
}

.view-details-value {
  @apply flex flex-row text-gray-100 basis-1/2 justify-center;
}

/* ------------------------- Popups & Modals ------------------------- */
.popup-overlay {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 
         flex justify-center items-center;
}

.popup-container {
  @apply bg-white p-6 rounded-lg shadow-lg w-1/2;
}

.popup-header {
  @apply text-lg font-bold mb-4;
}

.popup-description {
  @apply text-gray-600 mb-4;
}

.popup-table {
  @apply w-full border-collapse border border-gray-300;
}

.popup-table-header {
  @apply bg-gray-200;
}

.popup-table-cell {
  @apply border border-gray-300 px-4 py-2;
}

.popup-close-button {
  @apply mt-4 bg-red-500 text-white px-4 py-2 rounded;
}


@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}
